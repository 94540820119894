import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <p>{`Ever since my parents took my brother and I on a camper holiday in Canada when I was 3 years old, I've been intrigued by the concept of living in a van.
No doubt that beautiful experience has been the reason I was instantly hooked when I first learnt of the #van-life experience.`}</p>
    <p>{`To me, it means that I am able to find the most that life has to offer.
Whether it's kitesurfing along the shores of the Mediterranean Sea, hiking a trail along the fjords in Scandinavia, or snowboarding in the powder of the Alps.
The beauty lies in the freedom to adapt my life to whatever gives me happiness.
The purity of living in harmony with my surroundings, not only with regards to my environmental impact, but also migrating with the seasons.`}</p>
    <p>{`Nothing sparks more joy than flowing with whatever nature intended.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      